export const subjectsBySemester = {
    "semC Cycle": [
        "Single Variable Calculus",
        "Basic Electrical Engineering",
        "Engineering Mechanics",
        "Engineering Chemistry",
        "Design Thinking for Social Innovation",
    ],
    "semP Cycle": [
        "Multi Variable Calculus",
        "Basic Electronics",
        "Engineering Physics",
        "Basic Mechanical Engineering",
        "Engineering Exploration",
    ],
    "sem3": [
        "Graph Theory And Linear Algebra",
        "Discrete Mathematical Structures",
        "Computer Organization And Architecture",
        "Data Structures And Algorithms",
        "Database Management System",
        "Data Structures And Algorithms Lab",
        "Database Applications Lab",
    ],
    "sem4": [
        "Principles Of Compiler Design",
        "Object Oriented Programming",
        "Applied Statistics With R",
        "Microcontroller: Programming And Interfacing",
        "Exploratory Data Analysis",
        "Object Oriented Programming Lab",
        "Operating System Principles And Programming",
    ],
    "sem5": [
        "Software Engineering",
        "Computer Networks-1",
        "System Software",
"IOT",
	    "NLP",
"Web Technologies Lab",
        "Machine Learning",
        "System Software Lab",
    ],
    "sem6": [
        "Computer Networks-2",
        "Distributed & Cloud Computing",
        "Blockchain and Distributed Ledgers",
        "Computer Networks Lab",
    ],
    "sem7": [
        "Big Data & Analytics",
        "Information Security",
    ],
    "sem8": [
        "Capstone Project",
    ]

};
